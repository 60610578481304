<template>
  <div class="SceneSelection">
    <div class="title">“请选择你的使用场景”</div>
    <div class="toast">场景一旦选择不允许更改，请谨慎选择</div>
    <div v-for="(item,index) in list" :key="index" class="scene" @click="queryScene(item)" v-if="item.id == 1">
      <img :src="item.icon_url" class="ite_icon"></img>
      <div class="ite_box">
        <div class="ite_name">{{item.name}}</div>
        <div class="ite_info">{{item.describe}}</div>
      </div>
    </div>
    <div v-for="(item,index) in list" :key="index"  v-if="item.id == 2 || item.id == 3">
      <wx-open-launch-weapp  id="launch-btn" username="gh_abfd1d55fb11" :path="item.id == 2 ? 'pages/PlaceRegistration?mac='+mac : 'pages/DeviceRegistration?mac='+mac">
          <template>
            <script type="text/wxtag-template">
              <div class="scene" style="width: 305px;height: 98px;background: #eeeef4;border-radius: 15px;display: flex;align-items: center;margin-bottom: 30px;padding-left: 20px;">
                  <!-- <img :src="item.icon_url" style="width: 60px;margin-right: 30px;height: 60px;"></img> -->
                  <img class="img"  src="{{item.icon_url}}" alt=""  style="width: 60px;margin-right: 30px;height: 60px;"/>
                <div class="ite_box">
                  <div style="height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 25px;">{{item.name}}</div>
                  <div style="height: 34px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  line-height: 34px;">{{item.describe}}</div>
                </div>
              </div>
                <!-- <button style="width: 200px; height:45px; text-align: center; font-size: 17px; display: block; margin: 0 auto; padding: 8px 24px; border: none; border-radius: 4px; background-color: #07c160; color:#fff;">打开小程序</button> -->
            </script>
          </template>
        </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
import qs from 'qs'
import { Toast } from 'vant'
export default {
  name: 'KVip-no',
  data() {
    return {
      list: []
    }
  },
  created() {
    let _this = this
    _this.axios.get('/api/activate/getActivatePatternList').then(data=>{
      _this.list = data.data.list
    }).catch(err=>{
      console.log(err)
    })
    this.mac = this.getQueryVariable('mac')
     console.log(this.mac,'这是mac')
    this.is_support_film = this.getQueryVariable('is_support_film')
    // 初始化confi方法
    this.qianMing()
  },

  methods: {
    qianMing(){
      let wPath = window.document.location.href;
      console.log(wPath)
      this.axios.get('/wechat/config/getJsApiConfig?path_url='+wPath).then(data=>{
        console.log(data)
        this.chuConfig(data.data)
      }).catch(err=>{
        console.log(err)
      })
    },
    chuConfig(res){
      let _this = this
      wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印
          appId: res.app_id, // 必填，公众号的唯一标识
          timestamp:res.timestamp, // 必填，生成签名的时间戳
          nonceStr: res.nonce_str, // 必填，生成签名的随机串
          signature: res.signature,// 必填，签名
          jsApiList: ['openEnterpriseChat', 'openEnterpriseContact'], // 必填，需要使用的JS接口列表
         openTagList: ['wx-open-launch-weapp'] // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
      });
      wx.ready(function() {
        _this.isShow = true;
        // that.$nextTick(() => {
          let btn = document.getElementById('launch-btn');
          btn.addEventListener('launch', e => {
            console.log(111)
            console.log('success');
          });
          btn.addEventListener('error', e => {
            alert('小程序打开失败');
            console.log('fail', e.detail);
          });
        // });
      });
    },
    queryScene(item) {
      switch (item.id) {
        case 1:
          this.$router.push('/Selection')
          break
        case 2:
          console.log(222222)
          break
        case 3:
          console.log(33333)
          break

      }
    },
    weixinClosePage() {
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', weixin_ClosePage, false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', weixin_ClosePage);
          document.attachEvent('onWeixinJSBridgeReady', weixin_ClosePage);
        }
      } else {
        this.weixin_ClosePage();
      }
    },

    weixin_ClosePage() {
      WeixinJSBridge.call('closeWindow');
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == variable) {
          return pair[1];
        }
      }
      return false;
    }
    ,
    close: function () {

    }
    ,
    showpop: function () {
      this.show = true
    }
    ,
    queryitem: function (item, index) {
      item.check = !item.check
    }
    ,
    getLocalTime(nS) {
      return new Date(parseInt(nS) * 1000).toLocaleString().replace(/:\d{1,2}$/, ' ');
    }
    ,
    confirm: function () {
      let _this = this
      _this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      let scene = []
      _this.list.map(item => {
        if (item.check == true) {
          scene.push(item.id)
        }
      })
      if (scene.length > 0) {
        this.axios.post('/api/activate/newActiveM210Mac', {
          mac: _this.mac || '00:05:29:CE:01:6E',
          use_scene: scene,
        }).then(data => {
          if (data.code == 200) {
            _this.$toast.success('成功开通VIP');
            _this.start_time = _this.getLocalTime(data.data.active_time).substring(0, 10)
            _this.end_time = _this.getLocalTime(data.data.expire_time).substring(0, 10)
            console.log(_this.start_time)
            console.log(_this.end_time)
            _this.show = false
            _this.buyflag = true
          } else {
            _this.$toast.fail(data.msg);
            _this.show = false
          }
        }).catch(err => {
          console.log(err)
          _this.$toast.fail(data.msg);
        })
      } else {
        _this.$toast.fail('请选择场景');
      }

    }
  }
}
</script>
<style scoped>
.SceneSelection {
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  margin-top: 47px;
  height: 33px;
  font-size: 24px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #222222;
  line-height: 33px;
}

.toast {
  margin-top: 10px;
  height: 22px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  line-height: 22px;
  margin-bottom: 50px;
}

.scene {
  width: 305px;
  height: 98px;
  background: #eeeef4;
  border-radius: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding-left: 20px;
}
.ite_icon {
  width: 60px;
  margin-right: 30px;
  height: 60px;
}
.te_box {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.ite_name {
  height: 25px;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #222222;
  line-height: 25px;
}
.ite_info {
  height: 34px;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(34, 34, 34, 0.6);
  line-height: 34px;
}
</style>